import { Order } from "@src/core/projection/order/model/Order";
import OrderPreview from "@src/core/projection/order/model/OrderPreview";
import Brand from "@src/core/projection/brand/model/Brand";
import Provider from "@src/core/projection/provider/model/Provider";
import BrandOrigin from "@src/core/projection/brand/model/BrandOrigin";
import OrderSummary from "@src/core/projection/order/model/OrderSummary";
import Currency from "@src/core/projection/currency/model/Currency";
import OrderIdAndNumber from "@src/core/projection/order/model/OrderIdAndNumber";
import { SellingPrice } from "../../../../projection/prices/model/Prices";
import { convertDTOToSellingPrices } from "../../../../../shared/dataTransformer/prices";

type OrderPreviewResponseDto = {
  readonly id: string;
  readonly number: number;
  readonly status: string;
  readonly season: string;
  readonly b2b: boolean;
  readonly placed_on: string;
  readonly delivery_estimation: string;
  readonly delivered_on: string[];
  readonly brand: { id: string; name: string; origin: BrandOrigin; number: number };
  readonly provider: {
    readonly id: string;
    readonly name: string;
    readonly operation: string;
  };
  readonly total_unit_amount: number;
  readonly total_cost_amount: number;
  readonly segment: string;
};

export type OrderLineDto = {
  readonly id: string;
  readonly status: string;
  readonly delivery_estimation: string | null;
  readonly family: { id: string; name: string; category: string; segment: string };
  readonly group: string;
  readonly provider_product_reference: string;
  readonly premium: boolean;
  readonly printed: boolean;
  readonly title: string;
  readonly cost_price_currency: string;
  readonly cost_price_amount: number;
  readonly manufacturing_country: string;
  readonly units: {
    readonly id: string;
    readonly quantity: number;
    readonly color: { id: string };
    readonly size: { id: string };
  }[];
  readonly medias: {
    readonly id: string;
    readonly url: string;
  }[];
  readonly colors: {
    readonly id: string;
    readonly code: string;
    readonly hexadecimal: string;
    readonly name: string;
    readonly parent: string | null;
  }[];
  readonly features: {
    readonly id: string; // feature_id
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        id: string; // feature_id
        values: { id: string; value: string }[]; // feature_value_id[]
      };
    }[];
  }[];
  readonly neckline: string;
  readonly target_channels: string[];
  readonly risk_level: number;
};

type OrderResponseDto = {
  readonly id: string;
  readonly number: number;
  readonly season: string;
  readonly status: string;
  readonly placed_on: string;
  readonly delivered_on: Date[];
  readonly provider: { id: string; name: string };
  readonly brand: { id: string; name: string; origin: string };
  readonly recommendation_id: string;
  readonly b2b: boolean;
  readonly order_lines: OrderLineDto[];
  readonly segment: string;
};

export type OrderSummaryLineDto = {
  readonly id: string;
  readonly provider_product_reference: string;
  readonly title: string;
  readonly family: {
    readonly id: string;
    readonly name: string;
  };
  readonly cost_price_currency: string;
  readonly cost_price_amount: string;
  readonly total_unit_amount: number;
  readonly selling_prices: SellingPrice[];
};

type OrderSummaryResponseDto = {
  readonly id: string;
  readonly order_lines: OrderSummaryLineDto[];
};

type OrdersByProviderProductReferenceDto = {
  readonly id: string;
  readonly number: number;
}[];

const orderPreviewResponseDtoToOrderPreviewProjection = (order: OrderPreviewResponseDto): OrderPreview => ({
  id: order.id,
  number: order.number,
  status: order.status,
  season: {
    id: order.season,
    name: order.season,
  },
  placedOn: new Date(order.placed_on),
  deliveryEstimation: order.delivery_estimation ? new Date(order.delivery_estimation) : null,
  deliveredOn: order.delivered_on.map(deliveredOn => new Date(deliveredOn)),
  brand: order.brand
    ? {
        id: order.brand.id,
        name: order.brand.name,
        origin: order.brand.origin,
      }
    : ({} as Brand),
  provider: order.provider as Provider,
  totalUnitAmount: order.total_unit_amount,
  totalCostAmount: order.total_cost_amount,
  segment: order.segment,
});
const orderResponseDtoToOrderProjection = (order: OrderResponseDto): Order => ({
  id: order.id,
  number: order.number,
  status: order.status,
  placedOn: new Date(order.placed_on),
  deliveredOn: order.delivered_on ? order.delivered_on.map(deliveredOn => new Date(deliveredOn)) : [],
  provider: (order.provider
    ? {
        id: order.provider.id,
        name: order.provider.name,
      }
    : {}) as Provider,
  brand: {
    id: order.brand.id,
    origin: order.brand.origin as BrandOrigin,
    name: order.brand.name,
  },
  recommendationId: order.recommendation_id,
  b2b: order.b2b,
  season: `${order.season}`,
  orderLines: order.order_lines
    .map(orderLine => ({
      id: orderLine.id,
      status: orderLine.status,
      providerProductReference: orderLine.provider_product_reference,
      title: orderLine.title,
      deliveryEstimation: orderLine.delivery_estimation ? new Date(orderLine.delivery_estimation) : null,
      family: {
        id: orderLine.family.id,
        name: orderLine.family.name,
        category: orderLine.family.category,
        segment: orderLine.family.segment,
      },
      group: orderLine.group,
      premium: orderLine.premium,
      printed: orderLine.printed,
      costPriceCurrency: orderLine.cost_price_currency,
      costPriceAmount: orderLine.cost_price_amount,
      manufacturingCountry: orderLine.manufacturing_country,
      units: orderLine.units.map(unit => ({
        id: unit.id,
        quantity: unit.quantity,
        colorId: unit.color.id,
        sizeId: unit.size.id,
      })),
      medias: orderLine.medias,
      colors: orderLine.colors,
      features: orderLine.features,
      neckline: orderLine.neckline,
      targetChannels: orderLine.target_channels,
      riskLevel: orderLine.risk_level,
    }))
    .reverse(),
  segment: order.segment,
});

const orderSummaryResponseDtoToOrderSummary = (orderSummary: OrderSummaryResponseDto): OrderSummary => ({
  id: orderSummary.id,
  orderLines: orderSummary.order_lines?.map(line => {
    const sellingPrices = convertDTOToSellingPrices({
      ...line,
      selling_prices: line.selling_prices,
    });

    return {
      id: line.id,
      providerProducReference: line.provider_product_reference,
      title: line.title,
      family: {
        id: line.family.id,
        name: line.family.name,
      },
      costPriceCurrency: line.cost_price_currency as Currency,
      costPriceAmount: line.cost_price_amount,
      totalUnits: line.total_unit_amount,
      sellingPrices,
    };
  }),
});

const searchOrdersByProviderProductReferenceDtoToOrderIdAndNumber = (
  OrdersByProviderProductReference: OrdersByProviderProductReferenceDto,
): OrderIdAndNumber[] =>
  OrdersByProviderProductReference
    ? OrdersByProviderProductReference.map((order: OrderIdAndNumber) => ({
        id: order.id,
        number: order.number,
      }))
    : [];

export type { OrderPreviewResponseDto, OrderResponseDto };

export {
  orderPreviewResponseDtoToOrderPreviewProjection,
  orderResponseDtoToOrderProjection,
  orderSummaryResponseDtoToOrderSummary,
  searchOrdersByProviderProductReferenceDtoToOrderIdAndNumber,
};
