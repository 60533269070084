import {
  SearchPurchasesCriteria,
  PagedSearchPurchasesCriteria,
} from "../../../../projection/purchase/model/PurchaseView";

type PurchaseCountCriteriaDto = {
  readonly purchase_id: string | undefined;
  readonly number: string | undefined;
  readonly provider_operation: string | undefined;
  readonly provider_id: string | undefined;
  readonly invoice_number: string | undefined;
  readonly received_on: string | undefined;
  readonly group: string | undefined;
  readonly provider_product_reference: string | undefined;
};

type PurchaseSearchCriteriaDto = PurchaseCountCriteriaDto & {
  readonly page: number;
  readonly per_page: number;
  readonly order_by: string;
  readonly order_type: "ASC" | "DESC";
};

const purchaseCountCriteriaDto = ({
  purchaseId,
  purchaseNumber,
  providerType,
  providerId,
  invoiceNumber,
  receptionDate,
  group,
  providerReference,
}: SearchPurchasesCriteria): PurchaseCountCriteriaDto => ({
  purchase_id: purchaseId,
  number: purchaseNumber,
  provider_operation: providerType,
  provider_id: providerId,
  invoice_number: invoiceNumber,
  received_on: receptionDate,
  group: group,
  provider_product_reference: providerReference,
});

const purchaseSearchCriteriaDto = ({
  page,
  perPage,
  ...countPurchasesCriteriaParams
}: PagedSearchPurchasesCriteria): PurchaseSearchCriteriaDto => ({
  ...purchaseCountCriteriaDto(countPurchasesCriteriaParams),
  order_by: "number",
  order_type: "DESC",
  page,
  per_page: perPage,
});

export { purchaseCountCriteriaDto, purchaseSearchCriteriaDto };
